
import { defineComponent } from 'vue';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import {
  Actions as SubscriptionActions,
  Getters as SubscriptionGetters,
} from '@/store/enums/SubscriptionEnums';
import {
  Actions as InvoiceActions,
  Getters as InvoiceGetters,
} from '@/store/enums/InvoiceEnums';
import {
  Actions as PlanActions,
  Getters as PlanGetters,
} from '@/store/enums/PlanEnums';
import { Getters as StoreGetters } from '@/store/enums/StoreEnums';
import PlanColumnView from '@/components/tables/PlanColumnView.vue';
import DataTable from '@/components/datatables/KTDataTable.vue';

import { UpcomingInvoice } from '@/models/UpcomingInvoiceModel';
import { Invoice } from '@/models/InvoiceModel';
import { Subscription } from '@/models/SubscriptionModel';
import { Plan } from '@/models/PlanModel';
import { PaymentMethod } from '@/models/PaymentMethodModel';
import toasts from '@/utils/toasts';

const paymentMethodInit = {} as unknown as PaymentMethod;
const productDetailsInit = {} as unknown as Plan;
const subscriptionDetailsInit = {} as unknown as Subscription;
const invoiceListInit = {} as unknown as Array<Invoice>;
const upcomingInvoiceInit = {} as unknown as UpcomingInvoice;

export default defineComponent({
  name: 'invoices-details-page',
  data: () => ({
    hasSubscription: false,
    loadingInvoices: false,
    loadingDetails: false,
    loadingPlanProduct: false,
    loadCurrentSubscription: false,
    loadPaymentMethod: false,
    loadUpcoming: false,
    loadCancel: false,
    enableCancel: true,
    paymentMethodDetail: paymentMethodInit,
    productDetails: productDetailsInit,
    subscriptionDetails: subscriptionDetailsInit,
    invoiceLists: invoiceListInit,
    upcomingInvoiceDetails: upcomingInvoiceInit,
    headers: [
      { name: 'Invoice Number', key: 'number' },
      { name: 'Amount', key: 'amount' },
      { name: 'Status', key: 'status' },
      { name: 'Date', key: 'date' },
      { name: 'Invoice', key: 'action', align: 'right' },
    ],
  }),
  components: {
    DataTable,
    PlanColumnView,
  },
  async mounted() {
    this.loadInit();
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      actionErrors: SubscriptionGetters.GET_ACTION_ERROR,
      subscription: SubscriptionGetters.GET_SUBSCRIPTION,
      product: PlanGetters.GET_PLAN_PRODUCT,
      invoices: InvoiceGetters.GET_INVOICES,
      paymentMethod: InvoiceGetters.GET_PAYMENT_METHOD,
      upcomingInvoice: InvoiceGetters.GET_UPCOMING_INVOICE,
      defaultBGColor: StoreGetters.GET_DEFAULT_BACKGROUND_COLOR,
      defaultTextColor: StoreGetters.GET_DEFAULT_TEXT_COLOR,
    }),
  },
  methods: {
    ...mapActions({
      fetchCurrentSubscription: SubscriptionActions.FETCH_CURRENT_SUBSCRIPTION,
      fetchPlanProduct: PlanActions.FETCH_PLAN_PRODUCT,
      fetchInvoices: InvoiceActions.FETCH_INVOICES,
      fetchUpcomingInvoice: InvoiceActions.FETCH_UPCOMING_INVOICE,
      fetchPaymentMethod: InvoiceActions.FETCH_PAYMENT_METHOD, // @todo transfer to payment method module
      cancelSubscriptionAtPeriodEnd:
        SubscriptionActions.CANCEL_SUBSCRIPTION_AT_PERIOD_END,
      cancelSubscriptionImmediately:
        SubscriptionActions.CANCEL_SUBSCRIPTION_IMMEDIATELY,
    }),
    formatDate(date, format = 'MMM DD, hh:mm A') {
      return moment(new Date(date * 1000)).format(format);
    },
    moneyFormat(amount) {
      return `$ ${(amount / 100).toFixed(2)}`;
    },
    statusTag() {
      return this.subscription?.status;
    },
    toPlan() {
      this.$router.replace('/plan');
    },
    toBusinessDetails() {
      this.$router.replace('/profile/business-details');
    },
    async loadInit() {
      if (this.loadingDetails) return;
      this.loadingDetails = true;

      await this.fetchCurrentSubscription()
        .then(() => {
          this.hasSubscription = true;
        })
        .catch(() => {
          this.hasSubscription = false;
          this.loadingDetails = false;
        });

      if (this.hasSubscription) {
        this.loadingDetails = false;

        this.loadingInvoices = true;
        this.loadUpcoming = true;
        this.loadingPlanProduct = true;
        this.loadPaymentMethod = true;

        await this.handleFetchInvoices();

        this.enableCancel = false;
        this.loadUpcoming = false;
        this.loadPaymentMethod = false;
        this.loadingInvoices = false;
        this.loadingPlanProduct = false;
      }
    },

    async handleFetchPlanProducts() {
      const params = {
        id: await this.subscriptionDetails?.items?.data[0]?.price?.product,
      };

      return await this.fetchPlanProduct(params)
        .then(() => {
          this.loadingPlanProduct = false;
        })
        .catch(() => {
          this.loadingPlanProduct = false;
        })
        .finally(() => {
          this.loadingPlanProduct = false;
        });
    },

    async handleFetchUpcomingInvoices() {
      return await this.fetchUpcomingInvoice()
        .then(() => {
          this.loadUpcoming = false;
        })
        .catch(() => {
          console.log('handleFetchUpcomingInvoices', 'here');
          this.loadUpcoming = false;
        })
        .finally(() => {
          this.loadUpcoming = false;
        });
    },

    async handleFetchPaymentMethod() {
      const pmParams = {
        id: await this.subscriptionDetails?.default_payment_method,
      };
      return await this.fetchPaymentMethod(pmParams)
        .then(() => {
          this.loadPaymentMethod = false;
        })
        .catch(() => {
          this.loadPaymentMethod = false;
        })
        .finally(() => {
          this.loadPaymentMethod = false;
        });
    },

    async handleFetchInvoices() {
      return await this.fetchInvoices()
        .then(() => {
          this.loadingInvoices = false;
        })
        .catch(() => {
          this.loadingInvoices = false;
        })
        .finally(() => {
          this.loadingInvoices = false;
        });
    },

    handleCancelAtPeriodEnd() {
      if (this.loadCancel) return;
      this.loadCancel = true;

      toasts.confirm(
        'Do you really want to cancel your subscription? Your subscription will be removed at the end of the billing cycle.',
        async (result: any) => {
          alert(result);

          if (await result.isConfirmed) {
            await this.cancelSubscriptionAtPeriodEnd()
              .then(() => {
                toasts.success(
                  'Subscription has been cancelled successfully. Your subscription will stop at the end of the current period.',
                  () => {
                    this.loadCancel = false;
                    this.hasSubscription = false;
                    this.loadingDetails = false;
                    this.loadInit();
                  }
                );
              })
              .catch(() => {
                this.loadCancel = false;
                this.loadingDetails = true;
                const { errors, message } = this.actionErrors;
                toasts.error(errors, message);
              });
          }
          this.loadCancel = false;
        }
      );
    },
    handleCancelSubscription() {
      if (this.loadCancel) return;
      this.loadCancel = true;

      toasts.confirm(
        'Do you really want to cancel now your subscription?',
        async (result: any) => {
          if (result.isConfirmed) {
            await this.cancelSubscriptionImmediately()
              .then(() => {
                toasts.success(
                  'Subscription has been cancelled successfully.',
                  () => {
                    this.loadCancel = false;
                    this.hasSubscription = false;
                    this.loadingDetails = false;
                    this.loadInit();
                  }
                );
              })
              .catch(() => {
                this.loadCancel = false;
                this.loadingDetails = true;
                const { errors, message } = this.actionErrors;
                toasts.error(errors, message);
              });
          }
          this.loadCancel = false;
        }
      );
    },
    subscriptionStatus() {
      switch (this.subscription.status) {
        case 'trialing':
          return {
            text: 'On Trial',
            className: 'info',
          };
        case 'active':
          return {
            text: 'Active',
            className: 'success',
          };
        case 'canceled':
          return {
            text: 'Cancelled',
            className: 'danger',
          };
        default:
          return {
            text: '',
            className: '',
          };
      }
    },
  },

  watch: {
    async subscription(values) {
      this.subscriptionDetails = subscriptionDetailsInit;
      if (await values) {
        this.subscriptionDetails = await values;
      }
    },
    async upcomingInvoice(values) {
      this.upcomingInvoiceDetails = upcomingInvoiceInit;
      if (await values) {
        this.upcomingInvoiceDetails = await values;
      }
      this.loadUpcoming = false;
    },
    async invoices(values) {
      this.invoiceLists = invoiceListInit;
      if (await values) {
        this.invoiceLists = await values;
      }
      this.loadingInvoices = false;
    },
    async product(values) {
      this.productDetails = productDetailsInit;
      if (await values) {
        this.productDetails = await values;
      }
    },
    async paymentMethod(values) {
      this.paymentMethodDetail = paymentMethodInit;
      if (await values) {
        this.paymentMethodDetail = await values;
      }
      this.loadPaymentMethod = false;
    },
  },
});
